<template>
  <div
    style="width: 100%; padding: 10px; border: 1px solid lightgrey"
    class="mb-4"
  >
    <span
      class="
        d-flex
        mb-3
        justify-content-between
        border-bottom:
        1px
        solid
        lightgrey;
        box-shadow:
        0px
        0.5px
        0px;
      "
    >
      <h5>Invoice Details:</h5>
      <h5>{{ invoice.creation_date | moment("MMMM DD, YYYY") }}</h5>
    </span>
    <div
      v-if="
        ($session.get('user') == 'vendor' &&
          [0, 2, 9, 17, 5, 14, 24, 34, 44, 54].includes(invoice.inv_passed)) ||
        ($session.get('user') == 'pm' && [0, 8].includes(invoice.inv_passed)) ||
        ($session.get('user') == 'accountant' &&
          [0, 8, 7, 11, 21, 31, 41, 51].includes(invoice.inv_passed) &&
          toggleVselect) ||
        ($session.get('user') == 'manager' &&
          [6, 13, 23, 43].includes(invoice.inv_passed) &&
          invoice.accountant_paid === 0) ||
        ($session.get('user') == 'wss' && [0].includes(invoice.inv_passed))
      "
    >
      <label
        for="vwref_data"
        v-if="
          $session.get('user') == 'accountant'
            ? ![2, 3, 4, 5, -5].includes(invoice.qbyte_status)
            : ![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)
        "
        ><b>Cost Centre/Well</b></label
      >
      <b-modal
        id="confirm_well"
        ref="confirm_well"
        title="Are you sure you want to change the cost center/Well?"
        @ok="selectWell()"
        @cancel="resetValues()"
      >
        <p class="my-4">
          By Changing the cost centre/Well all the invoice coding will be
          removed and the invoice will be forwarded to the workflow approval of
          the new cost centre/Well.
        </p>
      </b-modal>
      <b-modal
        id="change_service"
        size="xl"
        ref="change_service"
        title="Service mismatched"
        @cancel="resetValues()"
        @ok="updateServices()"
      >
        <b-alert show variant="warning">
          The price per unit in the new cost centre/Well does not match the
          price per unit on the current cost centre/Well.
        </b-alert>
        <ol>
          <li>
            <b> New cost centre/Well :</b>
            <span style="color: green">
              {{ vwref_data.uwi }} ({{ vwref_data.projectname }})</span
            >
          </li>
          <br />
          <li>
            <b> Old cost centre/Well :</b>
            <span style="color: red">
              {{ vwref.uwi }} ({{ vwref.projectname }})</span
            >
          </li>
        </ol>
        <hr />
        <h5 v-if="editedData.length > 0">
          Service Need To Be Edited In Selected Project:
        </h5>
        <ul v-for="(val, i) of editedData">
          <li>
            <div class="mb-2 ml-2"><b> Service name: </b> {{ val.name }}</div>
            <b>a. Insert New Service:</b> <br />
            <span v-if="val.overwrite">
              <b-alert show variant="danger">
                <span style="color: red">
                  Need to overwrite uom1/pu1 in New Project/Site/Area:</span
                >
                {{ val.pu1 + "/" + val.uom1 }} with
                {{ val.amount + "/" + val.unit }}
              </b-alert>
            </span>
            <span v-else>
              <b-alert show variant="secondary">
                <b style="color: green">New Price/uom :</b>
                {{ val.amount + "/" + val.unit }}
              </b-alert>
            </span>
            <br />
            <b>b. Select From Existing Service: </b>
            <div v-for="(el, j) of allServices">
              <span v-if="val.name === el.name && val.vsid === el.vsid">
                <multiselect
                  @input="updateInvoice(el, val.vsid)"
                  v-model="el.currency"
                  placeholder="Select Existing Price"
                  tag-placeholder="Select Existing Price"
                  track-by="vsid"
                  :options="[
                    { uom: el.uom1, pu: el.pu1, uomPu: el.pu1 + '/' + el.uom1 },
                    el.uom2 != null
                      ? {
                          uom: el.uom2,
                          pu: el.pu2,
                          uomPu: el.pu2 + '/' + el.uom2,
                        }
                      : { uomPu: 'No Second Price', uom: null, pu: 0 },
                    el.uom3 != null
                      ? {
                          uom: el.uom3,
                          pu: el.pu3,
                          uomPu: el.pu3 + '/' + el.uom3,
                        }
                      : { uomPu: 'No Third Price', uom: null, pu: 0 },
                  ]"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :searchable="false"
                  :preserve-search="true"
                  :taggable="true"
                  label="uomPu"
                  :preselect-first="false"
                >
                </multiselect>
              </span>
            </div>
          </li>
          <hr />
        </ul>

        <h5 v-if="createdData.length > 0">Service To Be Created:</h5>
        <ol v-for="val of createdData">
          <li>
            <span>
              <b> New Service name: </b>
              <span v-if="val.isflexible === 'N'">
                {{ val.name }} -> {{ val.pu }} / {{ val.uom }}(<b
                  style="color: green"
                >
                  New UOM/Price: </b
                >)
              </span>
              <span v-else>
                {{ val.name }}(<b style="color: green">Service is Flexible. </b
                >)
              </span>
            </span>
          </li>
        </ol>
        <hr />

        <h5>
          Would you like to add the Old cost centre/Well to the new cost Cost
          centre/Well as new price and unit ?
        </h5>
      </b-modal>
      <v-select
        :clearable="false"
        :options="uwiData"
        class="mt-2"
        :filterable="true"
        :label="'uwi_4'"
        v-model="vwref_data"
        placeholder="Change Project"
        v-if="
          $session.get('user') == 'accountant'
            ? ![2, 3, 4, 5, -5].includes(invoice.qbyte_status)
            : ![7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)
        "
        @input="openModal()"
      >
        <template v-slot:option="option">
          <span>{{
            !option.uwi_2
              ? option.uwi
              : !option.uwi_3
              ? option.uwi_2
              : option.uwi_3
          }}</span> (<b>{{option.projectname}}</b>)
        </template>
        <template
          #selected-option="{
            uwi,
            uwi_2,
            uwi_3,
            option,
            deselect,
            multiple,
            disabled,
          }"
        >
          <span :style="{ color: vwref_data ? 'green' : 'red' }">{{
            !uwi_2 ? uwi : !uwi_3 ? uwi_2 : uwi_3
          }}</span>
        </template>
      </v-select>
      <label :style="{ color: vwref_data ? 'green' : 'red' }" v-if="projectid"
        ><b>Changed (Site/Area/Project):</b> {{ projectname }}</label
      >
    </div>

    <b-row>
      <b-col class="form-group col-md-6 col-lg-4">
        <label for="service-order-number" class="font-weight-bold"
          >Service Order Number</label
        >
        <input
          class="form-control"
          type="text"
          readonly
          placeholder="Service Order Number"
          v-model="invoice.service_order_number"
          id="service-order-number"
        />
      </b-col>

      <b-col class="form-group col-md-6 col-lg-4">
        <label for="program-number" class="font-weight-bold"
          >Program Number</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Program Number"
          v-model="invoice.program_number"
          readonly
          id="program-number"
        />
      </b-col>
      <b-col class="form-group col-md-6 col-lg-4">
        <label for="vendor-invoice-date" class="font-weight-bold"
          >Vendor Invoice Date</label
        >
        <input
          class="form-control"
          type="text"
          readonly
          placeholder="Vendor Invoice Number"
          :value="$moment(invoice.vendor_invoice_date).format('MM/DD/YYYY')"
          id="vendor-invoice-number"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col class="form-group col-6">
        <label for="vendor-invoice-number" class="font-weight-bold"
          >Vendor Invoice Number
        </label>
        <b-input-group>
          <b-form-input
            readonly
            v-if="isEditInvoice"
            class="form-control"
            type="text"
            placeholder="Vendor Invoice Number"
            v-model="invoice.vendor_invoice_number"
            id="vendor-invoice-number"
          ></b-form-input>
          <b-form-input
            v-else
            class="form-control"
            type="text"
            placeholder="Vendor Invoice Number"
            v-model="newVendorInvoiceNumber"
            id="vendor-invoice-number"
          ></b-form-input>

          <b-input-group-append>
            <span v-if="isEditInvoice">
              <b-button
                variant="warning"
                :disabled="
                  $session.get('user') == 'accountant'
                    ? [2, 3, 4, 5, -5].includes(invoice.qbyte_status)
                    : [7, 11, 21, 31, 41, 51].includes(invoice.inv_passed)
                "
                v-if="
                  ($session.get('user') == 'vendor' ||
                    $session.get('user') == 'accountant') &&
                  toggleEditVIN
                "
                style="color: white; cursor: pointer"
                @click="
                  isEditInvoice = !isEditInvoice;
                  newVendorInvoiceNumber = invoice.vendor_invoice_number;
                "
                ><i class="fa fa-edit"></i
              ></b-button>
            </span>
            <span v-else>
              <b-button
                variant="danger"
                id="budget-btn-danger"
                @click="isEditInvoice = !isEditInvoice"
                >X</b-button
              >
              <b-button
                variant="primary"
                id="budget-btn-save"
                @click="editVendorInvoiceNumber()"
                >&#x2714;</b-button
              >
            </span>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col class="form-group col-6">
        <label for="treatment-type" class="font-weight-bold"
          >Treatment Type</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Treatment Type"
          v-model="invoice.treatment_type"
          id="treatment-type"
          readonly
        />
      </b-col>

      <b-col class="form-group col-12">
        <label for="field-ticket-number" class="font-weight-bold"
          >Field Ticket Number</label
        >
        <textarea
          class="form-control"
          type="text"
          placeholder="Field Ticket Numbers"
          v-model="invoice.ticketnum"
          id="field-ticket-number"
          readonly
        ></textarea>
      </b-col>
    </b-row>

    <b-row v-if="highestAfe" class="mt-2">
      <b-col class="form-group col-6">
        <label for="projectname" class="font-weight-bold"
          >Current Project</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Current Project Name"
          :value="invoice.projectname"
          id="invoice-details"
          readonly
        />
      </b-col>

      <b-col class="form-group col-6">
        <label for="currentUWI" class="font-weight-bold">Current UWI</label>
        <input
          class="form-control"
          type="text"
          placeholder="Current UWI"
          :value="
            !highestAfe.uwi_2
              ? highestAfe.uwi
              : !highestAfe.uwi_3
              ? highestAfe.uwi_2
              : highestAfe.uwi_3
          "
          id="invoice-details"
          readonly
        />
      </b-col>
      <b-col class="form-group col-6">
        <label for="LicenseNumber" class="font-weight-bold"
          >License Number</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="License Number"
          :value="highestAfe.ln"
          id="invoice-details"
          readonly
        />
      </b-col>
      <b-col class="form-group col-6">
        <label for="SurfaceLocation" class="font-weight-bold"
          >Surface Location</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Surface Location"
          :value="highestAfe.lsdsurface"
          id="invoice-details"
          readonly
        />
      </b-col>
      <b-col class="form-group col-6">
        <label for="BottomLocation" class="font-weight-bold"
          >Bottom Location</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Surface Location"
          :value="highestAfe.lsdbottom"
          id="invoice-details"
          readonly
        />
      </b-col>
    </b-row>

    <b-row v-if="vwref" class="mt-2">
      <b-col class="form-group col-6">
        <label for="projectname" class="font-weight-bold"
          >Current Project</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Current Project Name"
          :value="invoice.projectname"
          id="invoice-details"
          readonly
        />
      </b-col>

      <b-col class="form-group col-6">
        <label for="currentUWI" class="font-weight-bold">Current UWI</label>
        <input
          class="form-control"
          type="text"
          placeholder="Current UWI"
          :value="
            !vwref.uwi_2 ? vwref.uwi : !vwref.uwi_3 ? vwref.uwi_2 : vwref.uwi_3
          "
          id="invoice-details"
          readonly
        />
      </b-col>
      <b-col class="form-group col-6">
        <label for="LicenseNumber" class="font-weight-bold"
          >License Number</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="License Number"
          :value="vwref.ln"
          id="invoice-details"
          readonly
        />
      </b-col>
      <b-col class="form-group col-6">
        <label for="SurfaceLocation" class="font-weight-bold"
          >Surface Location</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Surface Location"
          :value="vwref.lsdsurface"
          id="invoice-details"
          readonly
        />
      </b-col>
      <b-col class="form-group col-6">
        <label for="BottomLocation" class="font-weight-bold"
          >Bottom Location</label
        >
        <input
          class="form-control"
          type="text"
          placeholder="Surface Location"
          :value="vwref.lsdbottom"
          id="invoice-details"
          readonly
        />
      </b-col>
    </b-row>
  </div>
</template>

<script type="text/javascript">
import Multiselect from "vue-multiselect";
export default {
  name: "InvoiceDetails",
  components: {
    Multiselect,
  },
  props: [
    "invoice",
    "vwref",
    "highestAfe",
    "uwiData",
    "serviceData",
    "afes",
    "toggleVselect",
    "userData",
    "toggleEditVIN",
  ],
  data: function () {
    return {
      vwref_data: "",
      projectid: "",
      companyid: "",
      projectname: "",
      vendorServices: [],
      editedData: [],
      createdData: [],
      vsids: [],
      allServices: [],
      selectedService: "",
      selectedUom: [],
      isExisting: [],
      updateInvoices: [],
      showModal: true,
      newVendorInvoiceNumber: [],
      isEditInvoice: true,
    };
  },
  methods: {
    resetValues() {
      this.vwref_data = "";
      this.isExisting.length = 0;
      this.editedData.length = 0;
      this.createdData.length = 0;
      this.updateInvoices.length = 0;
      this.projectname = "";
      this.projectid = "";
      this.companyid = "";
    },
    openModal() {
      this.$refs["confirm_well"].show();
    },
    editVendorInvoiceNumber() {
      const obj = { vid: this.invoice.vid, vin: this.newVendorInvoiceNumber };
      this.$axios
        .post(
          "vendor/edit/invoice/invoice_number/invoiceid/" +
            this.invoice.invoiceid,
          obj
        )
        .then((res) => {
          if (res.data.status === 1) {
            this.invoice.vendor_invoice_number = this.newVendorInvoiceNumber;
            this.isEditInvoice = !this.isEditInvoice;
            this.$toasted.show("Vendor Invoice Number Edited.", {
              type: "success",
              duration: "3000",
            });
          } else {
            this.$toasted.show(`${res.data.msg}`, {
              type: "error",
              duration: "3000",
            });
          }
        })
        .catch((err) => {
          this.$toasted.show(`Error Edit Vendor Invoice Number.`, {
            type: "error",
            duration: "3000",
          });
        });
    },
    selectWell() {
      if (this.vwref_data) {
        this.projectid = this.vwref_data.projectid;
        this.projectname = this.vwref_data.projectname;
        this.companyid = this.vwref_data.companyid;
        this.verifyData();
      }
    },
    async verifyData() {
      const url =
        this.$session.get("user") === "vendor"
          ? "/vendor/get/service_chains/verify_data/" +
            this.companyid +
            "/" +
            this.projectid +
            "/" +
            this.invoice.vid +
            "/" +
            this.invoice.invoiceid
          : "/wss/get/service_chains/verify_data/" +
            this.companyid +
            "/" +
            this.projectid +
            "/" +
            this.invoice.vid +
            "/" +
            this.invoice.invoiceid;

      await this.$axios
        .get(url)
        .then((response) => {
          this.allServices = response.data.allServices;
          this.editedData = response.data.editedData;
          this.createdData = response.data.createdData;
          this.allServices.forEach((val) => (val.currency = ""));
          if (this.editedData.length > 0 || this.createdData.length > 0) {
            if (
              this.userData[0].role !== "wss" &&
              this.userData[0].role !== "vendor"
            ) {
              this.$refs["change_service"].show();
              this.insertVsid();
            } else {
              this.resetValues();
              this.$toasted.show("Service Mismatched.", {
                type: "error",
                duration: "3000",
              });
            }
          } else {
            this.updateProject();
          }
        })
        .catch((err) => {
          this.$toasted.show("Error.", {
            type: "error",
            duration: "3000",
          });
        });
    },
    RemovePrice(vsid) {
      const index = this.isExisting.findIndex((val) => val == vsid);
      this.updateInvoices.splice(index, 1);
      this.isExisting.splice(index, 1);
    },
    updateInvoice(service, vsid) {
      if (service.currency) {
        this.selectedService = service;
        this.selectedService["uom"] = service.currency.uom;
        this.selectedService["pu"] = service.currency.pu;
        this.selectedService["id"] = vsid;
        this.selectedService["invoiceid"] = this.invoice.invoiceid;
        this.isExisting.push(vsid);
        this.updateInvoices.push(this.selectedService);
      } else {
        if (this.isExisting.includes(vsid)) {
          this.RemovePrice(vsid);
        }
      }
    },
    async updateServices() {
      this.editedData = this.editedData.filter(
        (val) => !this.isExisting.includes(val.vsid)
      );
      const url = "/wss/update/service_chains/services/";
      this.$http
        .post(
          url + this.projectid + "/" + this.companyid + "/" + this.invoice.vid,
          {
            editedData: this.editedData,
            createdData: this.createdData,
            updatedInvoices: this.updateInvoices,
            userData: this.userData,
          }
        )
        .then((res) => {
          this.allServices = res.data.updated_services;
          this.editedData = [];
          this.createdData = [];
          this.updateInvoices = [];
          this.isExisting = [];
          this.updateProject();
        })
        .catch((err) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
    insertVsid() {
      this.editedData.forEach((val) => {
        const index = this.allServices.findIndex(
          (index) => index.name === val.name && index.type === val.type
        );
        !this.allServices[index]["vsid"]
          ? (this.allServices[index]["vsid"] = val.vsid)
          : this.allServices.push({
              ...this.allServices[index],
              ...{ vsid: val.vsid },
            });
      });
    },
    updateProject() {
      if (this.afes.length > 0) {
        this.$emit("removeAll", true);
      }
      this.serviceData.forEach((val) => {
        this.allServices.forEach((el) => {
          if (el.name === val.name && el.type === val.type) {
            console.log(el, val, "el val", el.name, val.name);
            this.userData[0]["oldProject"] = val.projectname;
            this.userData[0]["newProject"] = el.projectname;
            this.userData[0]["oldServiceid"] = el.serviceid;
            this.userData[0]["newServiceid"] = val.serviceid;
            this.userData[0]["originalInvoice"] =
              val.history === null ? true : false;
            val.serviceid = el.serviceid;
            val.projectid = this.projectid;
            val["vwref"] = this.vwref_data.uwi;
            val["object"] = false;
            val.projectname = el.projectname;
            val.history =
              val.history === null
                ? this.userData
                : [...this.userData, ...JSON.parse(val.history)];
          }
        });
      });

      this.invoice.projectid = this.projectid;
      this.invoice.vwref = this.vwref_data.uwi;
      const url =
        this.$session.get("user") === "vendor"
          ? "/vendor/update/service_chains/"
          : "/wss/update/service_chains/";
      this.$http
        .post(url, this.serviceData)
        .then((res) => {
          this.$router.push(`/${this.userData[0].role}`);
          this.$toasted.show("Project edited.", {
            type: "success",
            duration: "3000",
          });
        })
        .catch((err) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
    },
  },
};
</script>
