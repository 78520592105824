var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "mb-3" }, [_vm._v("Billing From:")]),
    _vm.avatar
      ? _c("div", { staticClass: "mt-3 mb-3" }, [
          _c("img", { staticClass: "companylogo", attrs: { src: _vm.avatar } })
        ])
      : _vm._e(),
    _c("h5", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.invoice.vcn))]),
    _c("ul", { staticClass: "fa-ul invoice-detail-lists" }, [
      _c("li", [
        _vm._m(0),
        _vm._v(_vm._s(_vm.invoice.vfname) + " " + _vm._s(_vm.invoice.vlname))
      ]),
      _c("li", [_vm._m(1), _vm._v(_vm._s(_vm.invoice.addressline1))]),
      _vm.invoice.addressline2
        ? _c("li", [_vm._m(2), _vm._v(_vm._s(_vm.invoice.addressline2))])
        : _vm._e(),
      _c("li", [
        _vm._m(3),
        _vm._v(_vm._s(_vm.invoice.city) + ", " + _vm._s(_vm.invoice.province))
      ]),
      _c("li", [_vm._m(4), _vm._v(_vm._s(_vm.invoice.postalcode))]),
      _vm._m(5),
      _c("li", [_vm._m(6), _vm._v(_vm._s(_vm.invoice.phone_num))]),
      _vm.invoice.fax_num
        ? _c("li", [_vm._m(7), _vm._v(_vm._s(_vm.invoice.fax_num))])
        : _vm._e()
    ]),
    _vm.invoice.bankaccount && !_vm.manager
      ? _c("h4", [_vm._v("Bank Account")])
      : _vm._e(),
    _vm.invoice.bankaccount && !_vm.manager
      ? _c("p", { staticClass: "font-weight-bold mt-2" }, [
          _c("i", { staticClass: "fa fa-university" }),
          _vm._v(" " + _vm._s(_vm.invoice.bankaccount))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", { staticClass: "fa-li" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-phone fa-flip-horizontal" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-fax" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }