var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v("Services ")]),
    _c("div", { staticClass: "table-responsive-sm" }, [
      _c("table", { staticClass: "table table-striped" }, [
        _c("thead", [
          _c("tr", { staticClass: "service-rows" }, [
            _c("th", { staticClass: "center service-name" }, [
              _vm._v("Service")
            ]),
            _c("th", { staticClass: "center service-name" }, [_vm._v("Code")]),
            _c("th", { staticClass: "datepickers right" }, [
              _vm._v("Start Date")
            ]),
            _c("th", { staticClass: "datepickers right" }, [
              _vm._v("End Date")
            ]),
            _c("th", { staticClass: "uom-select" }, [
              _vm._v("UOM "),
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "fa fa-question-circle",
                attrs: { title: "Unit Of Measurement" }
              })
            ]),
            _c("th", { staticClass: "price center" }, [_vm._v("Price/Unit")]),
            _c("th", { staticClass: "price center" }, [
              _vm._v("Discounted Price/Unit")
            ]),
            _c("th", { staticClass: "price center" }, [_vm._v("Quantity")]),
            _c("th", { staticClass: "price center" }, [_vm._v("Discount")]),
            _c("th", { staticClass: "right" }, [_vm._v("Total")])
          ])
        ]),
        _c(
          "tbody",
          _vm._l(_vm.sortedServiceData, function(service, index) {
            return _c("tr", { staticClass: "service-rows" }, [
              _c("td", { staticClass: "center price v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Service")
                ]),
                _vm._v("\n            " + _vm._s(service.name) + "\n          ")
              ]),
              _c("td", { staticClass: "center price v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Code")
                ]),
                _vm._v("\n            " + _vm._s(service.type) + "\n          ")
              ]),
              _c("td", { staticClass: "center price v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Start Date")
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("moment")(service.start_date, "MM/DD/YYYY")) +
                    "\n          "
                )
              ]),
              _c("td", { staticClass: "center price v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("End Date")
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("moment")(service.end_date, "MM/DD/YYYY")) +
                    "\n          "
                )
              ]),
              _c("td", { staticClass: "left price v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Unit Of Measurement")
                ]),
                _vm._v("\n            " + _vm._s(service.uom) + "\n          ")
              ]),
              _c("td", { staticClass: "price left v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Price")
                ]),
                _vm._v("\n            $" + _vm._s(service.pu) + "\n          ")
              ]),
              _c("td", { staticClass: "price left v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v(" Discounted Price/Unit")
                ]),
                _vm._v(
                  "\n             " +
                    _vm._s(
                      service.discount
                        ? "$" +
                            _vm._discount_price_per_unit(
                              service.discount,
                              service.pu
                            )
                        : service.name
                        ? "$" + service.pu
                        : null
                    ) +
                    "\n          "
                )
              ]),
              _c("td", { staticClass: "price left v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Quantity")
                ]),
                _vm._v(
                  "\n            " + _vm._s(service.quantity) + "\n            "
                ),
                /day|Day/.test(service.uom) &&
                _vm
                  .$moment(service.end_date)
                  .diff(_vm.$moment(service.start_date), "days") +
                  1 !=
                  service.quantity
                  ? _c("span", { staticClass: "ml-1" }, [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "fa fa-exclamation-triangle",
                        attrs: {
                          title:
                            "Quantity does not equal sum between start and end (" +
                            (parseInt(
                              _vm
                                .$moment(service.end_date)
                                .diff(_vm.$moment(service.start_date), "days")
                            ) +
                              1) +
                            ")!"
                        }
                      })
                    ])
                  : _vm._e()
              ]),
              _c("td", { staticClass: "price left v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Discount")
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatPrice(service.discount)) +
                    "%\n          "
                )
              ]),
              _c("td", { staticClass: "right v-align-middle" }, [
                _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                  _vm._v("Total")
                ]),
                _c("span", [
                  _vm._v(
                    "$" +
                      _vm._s(
                        _vm.formatPrice(
                          service.quantity * service.pu -
                            (service.discount / 100) *
                              service.quantity *
                              service.pu
                        )
                      )
                  )
                ])
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }