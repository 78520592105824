<template>
  <b-row id="total-row mt-3 mb-3">
    <b-col class="mt-3">
      <table id="total-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td>
              <b>$ {{ formatPrice(subtotal) }} </b>
            </td>
          </tr>
          <tr v-for="val in getParsedValue(invoice.invoice_taxes)">
            <td>{{ val.tax_name }} ({{ val.percentage }})%</td>
            <td>
              <input class="form-control" type="text" readonly placeholder="Tax Value"
                :value="formatPrice(calculateTax(val.tax_name, false))" step="any" id="program-number" />
            </td>
          </tr>
          <tr>
            <td>TAX $</td>
            <td>
              <input readonly class="form-control" type="text" placeholder="Enter Program Number" :value="tax"
                step="any" id="program-number" />
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              <strong>${{ formatPrice(invoice.total) }} {{ currency }}</strong>
            </td>
          </tr>
          <tr>
            <td>
              Quick Pay Discount %
              <i class="fa fa fa-question-circle" v-b-tooltip.hover title="Discount given to get paid quicker"></i>
            </td>
            <td>
              <input readonly type="text" class="form-control" placeholder="Add QuickPay Discount"
                :value="invoice.discount + '%'" />
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
    <b-col v-if="
      invoice.quickpay_total > 0 &&
      this.$moment(this.invoice.endtday) > this.$moment()
    " class="mt-3">
      <table id="total-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>QuickPay Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="height: 40px">Quick Pay Subtotal</td>
            <td style="height: 40px">
              $
              {{ formatPrice(subtotal - (subtotal * invoice.discount) / 100) }}
            </td>
          </tr>
          <tr v-for="val in getParsedValue(invoice.invoice_taxes)">
            <td>{{ val.tax_name }} ({{ val.percentage }})%</td>
            <td>
              <input class="form-control" type="text" readonly placeholder="Tax Value"
                :value="formatPrice(calculateTax(val.tax_name, true))" step="any" id="program-number" />
            </td>
          </tr>

          <tr>
            <td style="height: 40px">
              Quick Pay Net Days ({{ netDays + 1 }})
              <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Days given for quickpay payment"></i>
            </td>
            <td style="height: 40px">
              {{ invoice.endtday | moment('MM-DD-YYYY') }}
            </td>
          </tr>
          <tr>
            <td style="height: 38px">Quick Pay Total</td>
            <td style="height: 38px">
              $ {{ formatPrice(invoice.quickpay_total) }} {{ currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script type="text/javascript">
export default {
  name: 'InvoiceTotals',
  props: ['invoice', 'serviceData', 'currency'],
  data: function () {
    return {
      today: new Date(),
    };
  },
  methods: {
    calculateTax(tax_name, isQuickPay) {
      const taxes = this.serviceData.reduce((acc, item) => {
        const service_taxes =
          typeof item.applied_taxes !== 'string'
            ? item.applied_taxes
            : this.getParsedValue(item.applied_taxes);
        const required_tax = service_taxes.filter(
          (el) => el.isApplied && el.tax_name === tax_name
        );
        const total =
          item.quantity * item.pu -
          (item.discount / 100) * item.quantity * item.pu;
        const percentage =
          required_tax.length > 0 ? Number(required_tax[0].percentage) : 0;
        let quickpay = 1;
        if (isQuickPay) {
          quickpay = 1 - this.invoice.discount / 100;
        }
        const check = (percentage / 100) * total * quickpay;
        return (acc += check);
      }, 0);
     
      return Number(taxes);
    },
    getParsedValue(value) {
      if (value === undefined) {
        return [];
      }
      const taxes = JSON.parse(value);
      return typeof taxes === 'string' ? JSON.parse(taxes) : taxes;
    },
  },
  computed: {
    subtotal: function () {
      return this.serviceData.reduce(function (total, item) {
        return (
          total +
          item.quantity * item.pu -
          (item.discount / 100) * item.quantity * item.pu
        );
      }, 0);
    },
    tax: function () {
      return this.formatPrice(
        this.lineSubtotal * (this.invoice.tax_percentage / 100)
      );
    },
    lineSubtotal: function () {
      const subtotal = this.serviceData.reduce((total, item) => {
        if (item.tax) {
          return (
            total +
            item.quantity * item.pu -
            (item.discount / 100) * item.quantity * item.pu
          );
        } else {
          return total + 0;
        }
      }, 0);
      return subtotal;
    },
    qptax: function () { },
    netDays: function () {
      var a = this.$moment(this.invoice.endtday);
      var b = this.$moment();
      return a.diff(b, 'days');
    },
  },
  mounted() { },
};
</script>
<style scoped>
#total-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#total-table td {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
  font-size: medium;
  font-family: 'proxima-nova';
  font-weight: 700;
}

#total-table th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  background-color: #4b9955;
  color: white;
}

@media only screen and (max-width: 980px) {
  #total-row {
    display: block;
  }
}
</style>
