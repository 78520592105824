<template>
  <div>
    <div class="mt-4">
      <h4>Services</h4>
      <b-modal
        ref="service-tax-modal"
        class="service-tax-modal"
        centered
        size="lg"
        hide-footer
        :title="'Applied Taxes'"
        id="service-tax-modal"
      >
        <table class="tax-table">
          <thead>
            <tr>
              <th class="tax-action-th"></th>
              <th>Tax Name</th>
              <th>Tax Percentage</th>
              <th>Tax Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in selectedServiceTax.applied_taxes">
              <td class="tax-action">
                <input
                  type="checkbox"
                  disabled
                  v-model="val.isApplied"
                  :checked="val.isApplied"
                />
              </td>
              <td>
                <input
                  type="text"
                  :value="val.tax_name"
                  readonly
                  class="form-control"
                />
              </td>
              <td>
                <input
                  type="number"
                  readonly
                  :value="val.percentage"
                  class="form-control"
                />
              </td>
              <td>
                <input
                  type="text"
                  readonly
                  :value="
                    formatPrice(
                      Number(
                        `${selectedServiceTax.total}`.replaceAll(',', '')
                      ) *
                        (Number(val.percentage) / 100)
                    )
                  "
                  class="form-control"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </b-modal>
      <table id="Service-table">
        <thead>
          <tr id="Service-table-tr">
            <th style="background-color: #464646">Service</th>
            <th style="background-color: #464646">Code</th>
            <th style="background-color: #464646">Start - End Date</th>
            <th style="background-color: #464646">UOM</th>
            <th style="background-color: #464646">Price/Unit</th>
            <th style="background-color: #464646">Discounted Price/unit</th>
            <th style="background-color: #464646">Quantity</th>
            <th style="background-color: #464646">Discount(%)</th>
            <th style="background-color: #464646">Total</th>
            <th style="background-color: #464646">Applied Taxes</th>
          </tr>
        </thead>
        <tbody v-for="service in unassigned">
          <tr
            id="Service-table-tr"
            v-bind:style="{
              backgroundColor:
                service.isflexible !== 'N' ? 'cornsilk !important' : '#F8F8FF',
            }"
            style="cursor: pointer"
          >
            <td>
              <div class="view">
                <span>{{ service.name }}</span>
              </div>
            </td>
            <td>
              <div class="view">
                <span>{{ service.type }}</span>
              </div>
            </td>
            <td>
              <div class="view">
                {{ service.start_date | moment('MM/DD/YYYY') }} -
                {{ service.end_date | moment('MM/DD/YYYY') }} <br /><span
                  style="font-size: 10px"
                  >({{
                    parseInt(
                      $moment(service.end_date).diff(
                        $moment(service.start_date),
                        'days'
                      )
                    ) + 1
                  }}
                  Days)</span
                >
              </div>
            </td>
            <td>
              <div class="view">
                <span>{{ service.uom }}</span>
              </div>
            </td>
            <td>
              <div class="view">
                <span>${{ formatPrice(service.pu) }}</span>
              </div>
            </td>
            <td>
              <div class="view">
                <span>{{
                  service.discount
                    ? '$' +
                      _discount_price_per_unit(service.discount, service.pu)
                    : service.name
                    ? '$' + formatPrice(service.pu)
                    : null
                }}</span>
              </div>
            </td>
            <td>
              <div class="view">
                <span>{{ parseFloat(service.quantity) }}</span>
              </div>
            </td>

            <td>
              <div class="view">
                <span>{{ formatPrice(service.discount) }}%</span>
              </div>
            </td>

            <td>
              <div class="view">
                <span
                  >${{
                    formatPrice(
                      service.quantity * service.pu -
                        (service.discount / 100) * service.quantity * service.pu
                    )
                  }}</span
                >
              </div>
            </td>
            <td>
              <div class="view">
                <b-button
                  size="sm"
                  variant="success"
                  style="width: max-content;"
                  @click="openServiceTax(service)"
                  >Applied Taxes
                </b-button>
              </div>
            </td>
          </tr>
          <tr
            :style="{
              display:
                service.comment.replace(/\s/g, '').length > 0
                  ? 'contents'
                  : 'none',
            }"
          >
            <td colspan="100%">
              <textarea
                class="form-control mb-3 d-block mt-3"
                readonly
                placeholder="Add comments"
                v-model="service.comment"
              ></textarea>
            </td>
          </tr>
        </tbody>
        <tbody
          v-for="(afe, index) in merged"
          style="border: 1px solid lightgray; cursor: pointer"
        >
          <tr
            id="Service-table-tr"
            v-for="line in afe"
            style="color: white !important"
            v-bind:style="{
              backgroundColor:
                line.isflexible !== 'N'
                  ? 'darkseagreen !important'
                  : 'dimgray !important',
            }"
          >
            <td>
              <span>{{ line.name }}</span>
            </td>
            <td>
              <span>{{ line.type }}</span>
            </td>
            <td>
              {{ line.start_date | moment('MM/DD/YYYY') }} -
              {{ line.end_date | moment('MM/DD/YYYY') }} <br /><span
                style="font-size: 10px"
                >({{
                  parseInt(
                    $moment(line.end_date).diff(
                      $moment(line.start_date),
                      'days'
                    )
                  ) + 1
                }}
                Days)</span
              >
            </td>
            <td>
              <span>{{ line.uom }}</span>
            </td>
            <td>
              <span>${{ formatPrice(line.pu) }}</span>
            </td>
            <td>
              <span>
                {{
                  line.discount
                    ? '$' + _discount_price_per_unit(line.discount, line.pu)
                    : line.name
                    ? '$' + formatPrice(line.pu)
                    : null
                }}
                <!-- {{
                  line.discount
                    ? "$" +
                      _discount_price_per_unit(
                        line.discount,
                        line.pu
                      )
                    : line.name
                    ? "$" + formatPrice(line.pu)
                    : null
                }} -->
              </span>
            </td>
            <td>
              <span>{{ parseFloat(line.quantity) }}</span>
              <span
                class="ml-1"
                v-if="
                  /day|Day/.test(line.uom) &&
                    $moment(line.end_date).diff(
                      $moment(line.start_date),
                      'days'
                    ) +
                      1 !=
                      line.quantity
                "
              >
                <i
                  v-b-tooltip.hover
                  :title="
                    'Quantity does not equal sum between start and end dates (' +
                      (parseInt(
                        $moment(line.end_date).diff(
                          $moment(line.start_date),
                          'days'
                        )
                      ) +
                        1) +
                      ')!'
                  "
                  class="fa fa-exclamation-triangle"
                ></i>
              </span>
            </td>
            <td>
              <span>{{ formatPrice(line.discount) }}%</span>
            </td>
            <td>
              <span
                >${{
                  formatPrice(
                    line.quantity * line.pu -
                      (line.discount / 100) * line.quantity * line.pu
                  )
                }}</span
              >
            </td>
            <td>
              <b-button
                size="sm"
                variant="success"
                style="width: max-content;"
                @click="openServiceTax(line)"
                >Applied Taxes
              </b-button>
            </td>
          </tr>
          <tr
            :style="{
              display:
                afe[0].comment.replace(/\s/g, '').length > 0
                  ? 'contents'
                  : 'none',
            }"
          >
            <td colspan="100%">
              <textarea
                class="form-control mb-3 d-block mt-3"
                readonly
                placeholder="Add comments"
                v-model="afe[0].comment"
              ></textarea>
            </td>
          </tr>
          <tr v-show="afe[0].show_afe">
            <td colspan="100%" id="Service-table-child">
              <div>
                <table class="table no-bg afenum mb-0">
                  <thead>
                    <tr>
                      <th><strong>AFE or Cost Center / Well</strong></th>
                      <th><strong> Cost Code Chain </strong></th>
                      <th>
                        <strong>
                          Coding Percentage %
                          <i
                            class="fa fa-question-circle"
                            v-b-tooltip.hover
                            title="AFE or Cost Code or coding Percentage"
                          ></i
                        ></strong>
                      </th>
                      <th><strong>subtotal</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(line, aIndex) in afe[0].afes">
                      <td>
                        <i class="fa fa-chevron-circle-right"></i>
                        {{ line.afenum }}
                      </td>
                      <td>
                        {{ line.ccone_code }}--{{ line.cctwo_code }}--{{
                          line.ccthree_code
                        }}
                      </td>
                      <td>
                        {{ line.percentage }}
                      </td>
                      <td>
                        <input
                          type="text"
                          class="rt-percent form-control view"
                          readonly
                          :value="
                            formatPrice(
                              Number(afe[0].combined_total) *
                                (Number(line.percentage) / 100)
                            )
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'InvoiceAfes',
  props: ['afes', 'serviceData'],
  data: function() {
    return {
      order: '',
      ascDesc: 'asc',
      toggle: false,
      fieldTicketNumber: '',
      vendorInvoiceNumber: '',
      serviceOrderNumber: '',
      programNumber: '',
      treatmentType: '',
      vendorInvoiceDate: new Date().toISOString().slice(0, 10),
      serviceDatas: [],
      selectedServiceTax: {},
    };
  },
  methods: {
    openServiceTax(service, isSaved) {
      if (!isSaved) {
        // is service saved to db.
        service.total =
          service.quantity * service.pu -
          (service.discount / 100) * service.quantity * service.pu;
      }
      this.selectedServiceTax = service;
      this.$refs['service-tax-modal'].show();
    },
    sortServices(sort) {
      this.toggle = !this.toggle;
      if (this.toggle) {
        this.ascDesc = 'asc';
      } else {
        this.ascDesc = 'desc';
      }
      if (sort == 'name') {
        this.order = 'name';
      }
      if (sort == 'type') {
        this.order = 'type';
      }
      if (sort == 'date') {
        this.order = 'start_date';
      }
      if (sort == 'uom') {
        this.order = 'uom';
      }
      if (sort == 'price') {
        this.order = 'pu';
      }
      if (sort == 'quantity') {
        this.order = 'quantity';
      }
      if (sort == 'discount') {
        this.order = 'discount';
      }
      if (sort == 'total') {
        this.order = 'total';
      }
      if (sort == 'Discounted Price/Unit') {
        this.order = 'Discounted Price/Unit';
      }
    },
  },
  computed: {
    sortedAfes: function() {
      var temp = this.lodash
        .chain(this.afesProp)
        .groupBy((service) => line.service.vsid)
        .map((services, vsid) => ({ vsid, services }))
        .value();
      return this.lodash.orderBy(
        temp,
        'services[0].' + this.order,
        this.ascDesc
      );
    },
    afesProp: function() {
      const that = this;
      return this.afes.map(function(i) {
        i.total = that.formatPrice(
          i.quantity * i.pu - (i.discount / 100) * i.quantity * i.pu
        );
        return i;
      });
    },
    unassigned: function() {
      if (this.serviceData) {
        var data = [];
        this.serviceData.forEach((service) => {
          if (this.afesProp.filter((x) => x.vsid == service.vsid).length == 0) {
            data.push(service);
          }
        });
        return data;
      } else {
        var blank = [];
        return blank;
      }
    },
    merged: function() {
      this.highlightServices = [];
      var dict = {};
      var uni = this.lodash.uniqBy(this.serviceData, 'vsid');
      var afes = this.lodash.uniqBy(this.afesProp, 'apid');
      uni.forEach((service) => {
        var filtered = afes.filter((x) => x.vsid == service.vsid);
        service.afes = [];
        filtered.forEach((afe) => {
          service.afes.push({
            afenum: afe.afenum,
            ccone_code: afe.ccone_code,
            cctwo_code: afe.cctwo_code,
            ccthree_code: afe.ccthree_code,
            percentage: afe.percentage,
            pwcaid: afe.pwcaid,
          });
        });
      });

      uni.forEach((service) => {
        if (service.afes.length > 0) {
          dict[JSON.stringify(service)] = [];
        }
      });

      uni.forEach((service) => {
        service.afes.forEach((afe) => {
          dict[JSON.stringify(service)] = dict[JSON.stringify(service)].concat(
            afe
          );
        });
      });

      var dictAfe = {};

      for (var service in dict) {
        let key = JSON.stringify(dict[service]);
        if (!(key in dictAfe)) {
          dictAfe[key] = [JSON.parse(service)];
        } else {
          dictAfe[key] = dictAfe[key].concat(JSON.parse(service));
        }
      }
      var temp = [];
      for (var service in dictAfe) {
        dictAfe[service].forEach((val, i) => {
          val['show_afe'] = false;
          if (i === 0) val['isTop'] = true;
          val['same_coding'] = dictAfe[service].map((val) => val.vsid);
          if (i === dictAfe[service].length - 1) {
            const combined_total = dictAfe[service].reduce((acc, val) => {
              const service_total =
                val.quantity * val.pu -
                (val.discount / 100) * val.quantity * val.pu;
              return acc + Number(service_total);
            }, 0);
            val['combined_total'] = combined_total;
            val['show_afe'] = true;
            val['isTop'] = i === 0 ? true : false;
          }
        });

        temp.push(dictAfe[service]);
      }
      return temp.flat(Infinity).map((val) => [val]);
    },
  },
};
</script>

<style media="screen" lang="scss" scoped>
.container {
  cursor: grab;
  overflow: auto;
}

.loader {
  text-align: center;
  color: #bbbbbb;
}

#editAfe {
  cursor: pointer;
}
#afe-filter {
  display: flex;
  justify-content: end;
  padding: 10px;
}

#comment {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: white;
  font-size: small;
  font-weight: bold;
  border-color: transparent;
}

#comment {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: white;
  font-size: small;
  font-weight: bold;
  border-color: transparent;
}

#comment:hover {
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  color: #1e7e34;
  font-size: small;
  background-color: white;
  font-weight: bold;
  border-color: #1e7e34;
}

#total-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#total-table td {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
  font-size: medium;
  font-family: 'proxima-nova';
  font-weight: 700;
}

#total-table th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  background-color: #4b9955;
  color: white;
}

@media only screen and (max-width: 980px) {
  #total-row {
    display: block;
  }
}

#Service-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#Service-table #Service-table-tr td,
#Service-table #Service-table-tr th {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: smaller;
}

#Service-table #Service-table-tr th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: dimgrey;
  color: white;
}

#Service-table-child {
  font-size: inherit;
  background-color: white;
  /* padding: 10px; */
}

#Service-table-child th {
  font-size: smaller;
}

@media only screen and (max-width: 1200px) {
  #Service-table table,
  #Service-table thead,
  #Service-table tbody,
  #Service-table th,
  #Service-table td,
  #Service-table tr {
    display: block;
  }
  #Service-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #Service-table tr {
    border: 1px solid #ccc;
  }

  #Service-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  #Service-table td:before {
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bolder;
  }

  #Service-table-child td:nth-of-type(1):before {
    content: 'AFE or Cost Center / Well: ';
  }
  #Service-table-child td:nth-of-type(2):before {
    content: 'Cost Code Chain: ';
  }
  #Service-table-child td:nth-of-type(3):before {
    content: 'Cost Code or coding Percentage %: ';
  }
  #Service-table-child td:nth-of-type(4):before {
    content: 'Action';
  }

  #Service-table-tr td:nth-of-type(1):before {
    content: 'Service Name: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(2):before {
    content: 'Service Code: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(3):before {
    content: 'Start- End date: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(4):before {
    content: 'UOM: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(5):before {
    content: 'Price/Unit: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(6):before {
    content: 'Discounted Price/Unit: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(7):before {
    content: 'Quantity: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(8):before {
    content: 'Discount %: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(9):before {
    content: 'Total: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(10):before {
    content: 'Tax: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
  #Service-table-tr td:nth-of-type(11):before {
    content: 'Action: ';
    display: block;
    margin-bottom: 10px;
    font-size: larger;
  }
}
</style>
