<template>
  <div>
    <h4>Services </h4>
    <div class="table-responsive-sm">
      <table class="table table-striped">
        <thead>
          <tr class="service-rows">
            <th class="center service-name">Service</th>
            <th class="center service-name">Code</th>
            <th class="datepickers right">Start Date</th>
            <th class="datepickers right">End Date</th>
            <th class="uom-select">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
            <th class="price center">Price/Unit</th>
             <th class="price center">Discounted Price/Unit</th>
            <th class="price center">Quantity</th>
            <th class="price center">Discount</th>
            <th class="right">Total</th>
          </tr>
        </thead>
        
        <tbody>
          <tr class="service-rows" v-for="(service, index) in sortedServiceData">
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Service</h6>
              {{service.name}}
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Code</h6>
              {{service.type}}
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Start Date</h6>
              {{service.start_date | moment("MM/DD/YYYY")}}
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">End Date</h6>
              {{service.end_date | moment("MM/DD/YYYY")}}
            </td>

            <td class="left price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Unit Of Measurement</h6>
              {{service.uom}}
            </td>

            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Price</h6>
              ${{service.pu}}
            </td>
              <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none"> Discounted Price/Unit</h6>
               {{service.discount ?  '$' + _discount_price_per_unit(service.discount,service.pu) : service.name ?  '$' + service.pu: null }}
            </td>


            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Quantity</h6>
              {{service.quantity}}
              <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.end_date).diff($moment(service.start_date), 'days') + 1 != service.quantity">
                <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.end_date).diff($moment(service.start_date), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
              </span>
            </td>
            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Discount</h6>
              {{formatPrice(service.discount)}}%
            </td>
            <td class="right v-align-middle">
              <h6 class="d-xs-block d-xl-none">Total</h6>
              <span>${{formatPrice((service.quantity * service.pu) - ((service.discount/100) * service.quantity * service.pu))}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'InvoiceServices',
  props: ['serviceData', 'afes'],
  data: function() {
    return {

    }
  },
  computed: {
    unassigned: function(){
      var data = [];
      this.serviceData.forEach(service => {
        if(this.afes.filter(x=>x.vsid == service.vsid).length == 0){
          data.push(service)
        }
      })

      //return this.lodash.uniqBy(this.lodash.sortBy(this.serviceData, 'name'), 'vsid');
      //return this.lodash.sortBy(this.serviceData, 'name');
      return data;
    },
  },
  created(){
  }
}
</script>
