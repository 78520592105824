var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("h4", [_vm._v("Services")]),
        _c(
          "b-modal",
          {
            ref: "service-tax-modal",
            staticClass: "service-tax-modal",
            attrs: {
              centered: "",
              size: "lg",
              "hide-footer": "",
              title: "Applied Taxes",
              id: "service-tax-modal"
            }
          },
          [
            _c("table", { staticClass: "tax-table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { staticClass: "tax-action-th" }),
                  _c("th", [_vm._v("Tax Name")]),
                  _c("th", [_vm._v("Tax Percentage")]),
                  _c("th", [_vm._v("Tax Amount")])
                ])
              ]),
              _c(
                "tbody",
                _vm._l(_vm.selectedServiceTax.applied_taxes, function(val, i) {
                  return _c("tr", [
                    _c("td", { staticClass: "tax-action" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: val.isApplied,
                            expression: "val.isApplied"
                          }
                        ],
                        attrs: { type: "checkbox", disabled: "" },
                        domProps: {
                          checked: val.isApplied,
                          checked: Array.isArray(val.isApplied)
                            ? _vm._i(val.isApplied, null) > -1
                            : val.isApplied
                        },
                        on: {
                          change: function($event) {
                            var $$a = val.isApplied,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(val, "isApplied", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    val,
                                    "isApplied",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(val, "isApplied", $$c)
                            }
                          }
                        }
                      })
                    ]),
                    _c("td", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: val.tax_name }
                      })
                    ]),
                    _c("td", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "number", readonly: "" },
                        domProps: { value: val.percentage }
                      })
                    ]),
                    _c("td", [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", readonly: "" },
                        domProps: {
                          value: _vm.formatPrice(
                            Number(
                              ("" + _vm.selectedServiceTax.total).replaceAll(
                                ",",
                                ""
                              )
                            ) *
                              (Number(val.percentage) / 100)
                          )
                        }
                      })
                    ])
                  ])
                }),
                0
              )
            ])
          ]
        ),
        _c(
          "table",
          { attrs: { id: "Service-table" } },
          [
            _vm._m(0),
            _vm._l(_vm.unassigned, function(service) {
              return _c("tbody", [
                _c(
                  "tr",
                  {
                    staticStyle: { cursor: "pointer" },
                    style: {
                      backgroundColor:
                        service.isflexible !== "N"
                          ? "cornsilk !important"
                          : "#F8F8FF"
                    },
                    attrs: { id: "Service-table-tr" }
                  },
                  [
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [_vm._v(_vm._s(service.name))])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [_vm._v(_vm._s(service.type))])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(service.start_date, "MM/DD/YYYY")
                            ) +
                            " -\n              " +
                            _vm._s(
                              _vm._f("moment")(service.end_date, "MM/DD/YYYY")
                            ) +
                            " "
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v(
                            "(" +
                              _vm._s(
                                parseInt(
                                  _vm
                                    .$moment(service.end_date)
                                    .diff(
                                      _vm.$moment(service.start_date),
                                      "days"
                                    )
                                ) + 1
                              ) +
                              "\n                Days)"
                          )
                        ])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [_vm._v(_vm._s(service.uom))])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [
                          _vm._v("$" + _vm._s(_vm.formatPrice(service.pu)))
                        ])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              service.discount
                                ? "$" +
                                    _vm._discount_price_per_unit(
                                      service.discount,
                                      service.pu
                                    )
                                : service.name
                                ? "$" + _vm.formatPrice(service.pu)
                                : null
                            )
                          )
                        ])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [
                          _vm._v(_vm._s(parseFloat(service.quantity)))
                        ])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatPrice(service.discount)) + "%"
                          )
                        ])
                      ])
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "view" }, [
                        _c("span", [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.formatPrice(
                                  service.quantity * service.pu -
                                    (service.discount / 100) *
                                      service.quantity *
                                      service.pu
                                )
                              )
                          )
                        ])
                      ])
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "view" },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { width: "max-content" },
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.openServiceTax(service)
                                }
                              }
                            },
                            [_vm._v("Applied Taxes\n              ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "tr",
                  {
                    style: {
                      display:
                        service.comment.replace(/\s/g, "").length > 0
                          ? "contents"
                          : "none"
                    }
                  },
                  [
                    _c("td", { attrs: { colspan: "100%" } }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: service.comment,
                            expression: "service.comment"
                          }
                        ],
                        staticClass: "form-control mb-3 d-block mt-3",
                        attrs: { readonly: "", placeholder: "Add comments" },
                        domProps: { value: service.comment },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(service, "comment", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]
                )
              ])
            }),
            _vm._l(_vm.merged, function(afe, index) {
              return _c(
                "tbody",
                {
                  staticStyle: {
                    border: "1px solid lightgray",
                    cursor: "pointer"
                  }
                },
                [
                  _vm._l(afe, function(line) {
                    return _c(
                      "tr",
                      {
                        staticStyle: { color: "white !important" },
                        style: {
                          backgroundColor:
                            line.isflexible !== "N"
                              ? "darkseagreen !important"
                              : "dimgray !important"
                        },
                        attrs: { id: "Service-table-tr" }
                      },
                      [
                        _c("td", [_c("span", [_vm._v(_vm._s(line.name))])]),
                        _c("td", [_c("span", [_vm._v(_vm._s(line.type))])]),
                        _c("td", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("moment")(line.start_date, "MM/DD/YYYY")
                              ) +
                              " -\n            " +
                              _vm._s(
                                _vm._f("moment")(line.end_date, "MM/DD/YYYY")
                              ) +
                              " "
                          ),
                          _c("br"),
                          _c("span", { staticStyle: { "font-size": "10px" } }, [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  parseInt(
                                    _vm
                                      .$moment(line.end_date)
                                      .diff(
                                        _vm.$moment(line.start_date),
                                        "days"
                                      )
                                  ) + 1
                                ) +
                                "\n              Days)"
                            )
                          ])
                        ]),
                        _c("td", [_c("span", [_vm._v(_vm._s(line.uom))])]),
                        _c("td", [
                          _c("span", [
                            _vm._v("$" + _vm._s(_vm.formatPrice(line.pu)))
                          ])
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  line.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          line.discount,
                                          line.pu
                                        )
                                    : line.name
                                    ? "$" + _vm.formatPrice(line.pu)
                                    : null
                                ) +
                                "\n              "
                            )
                          ])
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(_vm._s(parseFloat(line.quantity)))
                          ]),
                          /day|Day/.test(line.uom) &&
                          _vm
                            .$moment(line.end_date)
                            .diff(_vm.$moment(line.start_date), "days") +
                            1 !=
                            line.quantity
                            ? _c("span", { staticClass: "ml-1" }, [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "fa fa-exclamation-triangle",
                                  attrs: {
                                    title:
                                      "Quantity does not equal sum between start and end dates (" +
                                      (parseInt(
                                        _vm
                                          .$moment(line.end_date)
                                          .diff(
                                            _vm.$moment(line.start_date),
                                            "days"
                                          )
                                      ) +
                                        1) +
                                      ")!"
                                  }
                                })
                              ])
                            : _vm._e()
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatPrice(line.discount)) + "%")
                          ])
                        ]),
                        _c("td", [
                          _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatPrice(
                                    line.quantity * line.pu -
                                      (line.discount / 100) *
                                        line.quantity *
                                        line.pu
                                  )
                                )
                            )
                          ])
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { width: "max-content" },
                                attrs: { size: "sm", variant: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.openServiceTax(line)
                                  }
                                }
                              },
                              [_vm._v("Applied Taxes\n            ")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c(
                    "tr",
                    {
                      style: {
                        display:
                          afe[0].comment.replace(/\s/g, "").length > 0
                            ? "contents"
                            : "none"
                      }
                    },
                    [
                      _c("td", { attrs: { colspan: "100%" } }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: afe[0].comment,
                              expression: "afe[0].comment"
                            }
                          ],
                          staticClass: "form-control mb-3 d-block mt-3",
                          attrs: { readonly: "", placeholder: "Add comments" },
                          domProps: { value: afe[0].comment },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(afe[0], "comment", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: afe[0].show_afe,
                          expression: "afe[0].show_afe"
                        }
                      ]
                    },
                    [
                      _c(
                        "td",
                        {
                          attrs: { colspan: "100%", id: "Service-table-child" }
                        },
                        [
                          _c("div", [
                            _c(
                              "table",
                              { staticClass: "table no-bg afenum mb-0" },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _vm._m(1, true),
                                    _vm._m(2, true),
                                    _c("th", [
                                      _c("strong", [
                                        _vm._v(
                                          "\n                        Coding Percentage %\n                        "
                                        ),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass: "fa fa-question-circle",
                                          attrs: {
                                            title:
                                              "AFE or Cost Code or coding Percentage"
                                          }
                                        })
                                      ])
                                    ]),
                                    _vm._m(3, true)
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(afe[0].afes, function(line, aIndex) {
                                    return _c("tr", [
                                      _c("td", [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-chevron-circle-right"
                                        }),
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(line.afenum) +
                                            "\n                    "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(line.ccone_code) +
                                            "--" +
                                            _vm._s(line.cctwo_code) +
                                            "--" +
                                            _vm._s(line.ccthree_code) +
                                            "\n                    "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(line.percentage) +
                                            "\n                    "
                                        )
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          staticClass:
                                            "rt-percent form-control view",
                                          attrs: { type: "text", readonly: "" },
                                          domProps: {
                                            value: _vm.formatPrice(
                                              Number(afe[0].combined_total) *
                                                (Number(line.percentage) / 100)
                                            )
                                          }
                                        })
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                2
              )
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { attrs: { id: "Service-table-tr" } }, [
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Service")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Code")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Start - End Date")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("UOM")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Price/Unit")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Discounted Price/unit")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Quantity")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Discount(%)")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Total")
        ]),
        _c("th", { staticStyle: { "background-color": "#464646" } }, [
          _vm._v("Applied Taxes")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("strong", [_vm._v("AFE or Cost Center / Well")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("strong", [_vm._v(" Cost Code Chain ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("strong", [_vm._v("subtotal")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }