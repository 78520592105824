<template>
  <div>
    <h4 class="mb-3">Billing From:</h4>
    <div class="mt-3 mb-3" v-if="avatar">
      <img class="companylogo" v-bind:src="avatar" />
    </div>
    <h5 class="mb-3">{{invoice.vcn}}</h5>
    <ul class="fa-ul invoice-detail-lists">
      <li><span class="fa-li"><i class="fa fa-user"></i></span>{{invoice.vfname}} {{invoice.vlname}}</li>
      <li><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.addressline1}}</li>
      <li v-if="invoice.addressline2"><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.addressline2}}</li>
      <li><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.city}}, {{invoice.province}}</li>
      <li><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.postalcode}}</li>
      <li><span class="fa-li"></span></li>
      <li><span class="fa-li"><i class="fa fa-phone fa-flip-horizontal"></i></span>{{invoice.phone_num}}</li>
      <li v-if="invoice.fax_num"><span class="fa-li"><i class="fa fa-fax"></i></span>{{invoice.fax_num}}</li>
    </ul>
    <h4 v-if="invoice.bankaccount && !manager">Bank Account</h4>
    <p class="font-weight-bold mt-2" v-if="invoice.bankaccount && !manager"><i class="fa fa-university"></i> {{invoice.bankaccount}}</p>
  </div>
</template>

<script type="text/javascript">
import * as jwt_decode from 'jwt-decode';

export default {
  name: 'VendorDetails',
  props: ['invoice', 'manager', 'vid'],
  data: function() {
    return {
      avatar: ""
    }
  },
  watch: {
    invoice: function(){
      if(this.vid){
        let token = this.$session.get('jwt');
        var decoded = jwt_decode(token);
        this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
            this.avatar = response.data.avatar;
        }).catch(error => {
            console.log("Error getting avatar " + error);
        })
      }
      else {
        this.$http.get('/get/vendor/avatar/' + this.invoice.vid ).then(response => {
            this.avatar = response.data.avatar;
        }).catch(error => {
            console.log("Error getting avatar " + error);
        })
      }
    }
  },
}
</script>
