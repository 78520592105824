var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h4",
      { staticClass: "d-inline" },
      [
        _c("i", { staticClass: "nav-icon icon-layers" }),
        _vm._v(" Invoice No: " + _vm._s(_vm.invoice.invnum) + "\n    "),
        _c(
          "b-button",
          {
            staticClass: "pull-right pl-3 btn-sm font-weight-bold",
            attrs: { variant: "primary" },
            on: {
              click: function($event) {
                return _vm.printInvoice(
                  _vm.invoice,
                  _vm.vwref,
                  _vm.highestAfe,
                  _vm.serviceData,
                  _vm.afes,
                  _vm.currency,
                  _vm.subtotal,
                  _vm.tax,
                  _vm.company_name,
                  _vm.manager,
                  _vm.vendorLogo,
                  _vm.companyLogo,
                  _vm.allHistorySorted,
                  _vm.invoiceModificationHistoryChanges
                )
              }
            }
          },
          [
            _c("span", { staticClass: "mt-2 d-none d-sm-inline mr-1" }, [
              _vm._v("Print / Download PDF ")
            ]),
            _c("i", { staticClass: "fa fa-print" })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }