var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { id: "total-row mt-3 mb-3" } },
    [
      _c("b-col", { staticClass: "mt-3" }, [
        _c("table", { attrs: { id: "total-table" } }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Description")]),
              _c("th", [_vm._v("Amount")])
            ])
          ]),
          _c(
            "tbody",
            [
              _c("tr", [
                _c("td", [_vm._v("Subtotal")]),
                _c("td", [
                  _c("b", [
                    _vm._v("$ " + _vm._s(_vm.formatPrice(_vm.subtotal)) + " ")
                  ])
                ])
              ]),
              _vm._l(_vm.getParsedValue(_vm.invoice.invoice_taxes), function(
                val
              ) {
                return _c("tr", [
                  _c("td", [
                    _vm._v(
                      _vm._s(val.tax_name) +
                        " (" +
                        _vm._s(val.percentage) +
                        ")%"
                    )
                  ]),
                  _c("td", [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        readonly: "",
                        placeholder: "Tax Value",
                        step: "any",
                        id: "program-number"
                      },
                      domProps: {
                        value: _vm.formatPrice(
                          _vm.calculateTax(val.tax_name, false)
                        )
                      }
                    })
                  ])
                ])
              }),
              _c("tr", [
                _c("td", [_vm._v("TAX $")]),
                _c("td", [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      readonly: "",
                      type: "text",
                      placeholder: "Enter Program Number",
                      step: "any",
                      id: "program-number"
                    },
                    domProps: { value: _vm.tax }
                  })
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("Total")]),
                _c("td", [
                  _c("strong", [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.formatPrice(_vm.invoice.total)) +
                        " " +
                        _vm._s(_vm.currency)
                    )
                  ])
                ])
              ]),
              _c("tr", [
                _c("td", [
                  _vm._v("\n            Quick Pay Discount %\n            "),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass: "fa fa fa-question-circle",
                    attrs: { title: "Discount given to get paid quicker" }
                  })
                ]),
                _c("td", [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      readonly: "",
                      type: "text",
                      placeholder: "Add QuickPay Discount"
                    },
                    domProps: { value: _vm.invoice.discount + "%" }
                  })
                ])
              ])
            ],
            2
          )
        ])
      ]),
      _vm.invoice.quickpay_total > 0 &&
      this.$moment(this.invoice.endtday) > this.$moment()
        ? _c("b-col", { staticClass: "mt-3" }, [
            _c("table", { attrs: { id: "total-table" } }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Description")]),
                  _c("th", [_vm._v("QuickPay Amount")])
                ])
              ]),
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c("td", { staticStyle: { height: "40px" } }, [
                      _vm._v("Quick Pay Subtotal")
                    ]),
                    _c("td", { staticStyle: { height: "40px" } }, [
                      _vm._v(
                        "\n            $\n            " +
                          _vm._s(
                            _vm.formatPrice(
                              _vm.subtotal -
                                (_vm.subtotal * _vm.invoice.discount) / 100
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._l(
                    _vm.getParsedValue(_vm.invoice.invoice_taxes),
                    function(val) {
                      return _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(val.tax_name) +
                              " (" +
                              _vm._s(val.percentage) +
                              ")%"
                          )
                        ]),
                        _c("td", [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              readonly: "",
                              placeholder: "Tax Value",
                              step: "any",
                              id: "program-number"
                            },
                            domProps: {
                              value: _vm.formatPrice(
                                _vm.calculateTax(val.tax_name, true)
                              )
                            }
                          })
                        ])
                      ])
                    }
                  ),
                  _c("tr", [
                    _c("td", { staticStyle: { height: "40px" } }, [
                      _vm._v(
                        "\n            Quick Pay Net Days (" +
                          _vm._s(_vm.netDays + 1) +
                          ")\n            "
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "ml-2 fa fa fa-question-circle",
                        attrs: { title: "Days given for quickpay payment" }
                      })
                    ]),
                    _c("td", { staticStyle: { height: "40px" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("moment")(_vm.invoice.endtday, "MM-DD-YYYY")
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticStyle: { height: "38px" } }, [
                      _vm._v("Quick Pay Total")
                    ]),
                    _c("td", { staticStyle: { height: "38px" } }, [
                      _vm._v(
                        "\n            $ " +
                          _vm._s(_vm.formatPrice(_vm.invoice.quickpay_total)) +
                          " " +
                          _vm._s(_vm.currency) +
                          "\n          "
                      )
                    ])
                  ])
                ],
                2
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }