var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "mb-3" }, [_vm._v("Billing To:")]),
    _vm.avatar
      ? _c("div", { staticClass: "mt-3 mb-3" }, [
          _c("img", { staticClass: "companylogo", attrs: { src: _vm.avatar } })
        ])
      : _vm._e(),
    _c("h5", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.company_name))]),
    _c("ul", { staticClass: "fa-ul invoice-detail-lists" }, [
      _c("li", [_vm._m(0), _vm._v(_vm._s(_vm.invoice.company_addressline1))]),
      _vm.invoice.company_addressline2
        ? _c("li", [
            _vm._m(1),
            _vm._v(_vm._s(_vm.invoice.company_addressline2))
          ])
        : _vm._e(),
      _c("li", [
        _vm._m(2),
        _vm._v(
          _vm._s(_vm.invoice.company_city) +
            ", " +
            _vm._s(_vm.invoice.company_province)
        )
      ]),
      _c("li", [_vm._m(3), _vm._v(_vm._s(_vm.invoice.company_postalcode))])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-building" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }